import React from "react"
import { graphql, useStaticQuery } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Process from "../components/process"

import InfoWindow from "../components/infoWindow"

const more = [
  <div>
    <ul>
      <li> Inside and outside clean </li>
      <li> Wet vacuum dried </li>
      <li> Deodorised with biodegradable cleaning products </li>
      <li> Legal water disposal </li>
    </ul>
    <p>Contact us to learn more.</p>
  </div>,
  <div>
    <ul>
      <li> Priority service </li>
      <li> Guaranteed 5 hour turnaround </li>
      <li> Regular Checkups </li>
      <li> First-time discount </li>
    </ul>
    Contact us for more information.
    <p></p>
  </div>,
  <div>
    <ul>
      <li> Deoderisers </li>
      <li> Periodic Bin Inspections </li>
      <li> Service Reports </li>
      <li> 24/7 Call-outs </li>
      <li> and more... </li>
    </ul>
    Contact us to customise the service or recieve a demo service report.
    <p></p>
  </div>,
]

const InspectionReporting = (
  <div>
    <h2> Professional Residential Bin Cleaning.</h2>
    <i></i>
    <div>
      <p>
        <h4>Wheelie Bin Cleaning</h4>
        We thoroughly clean the bins using hot and cold water, making sure that
        all dirt and grime is removed. We add a fully environmentally friendly
        and degradable liner and spray with our scented deodorisor.
        <h4> Regular, adhoc and one-off services. </h4>
        We will visit your home each day your bins are emptied and
        professionally clean your wheelie bins to remove smells and bacteria.{" "}
        <br />
      </p>
      <ul>
        <li>
          <h4> Fortnightly service </h4> - $10 per bin
        </li>

        <li>
          <h4> Monthly service </h4> - $12 per bin
        </li>

        <li>
          <h4> Once Off </h4> - $15 per bin
        </li>
      </ul>
      <i style={{ textAlign: "center" }}>
        {" "}
        Based On 2 x 240L wheelie bins. Entirely negotiable.
      </i>
      <br />
      <h4>Discount.</h4> Discounts apply to bulk orders. <br />
      <a href="tel:0413231255">Call us for details</a>
    </div>
  </div>
)
const RegularService = (
  <div>
    <h2> Commercial Bin Cleaning</h2>
    <p>
      <h4>Prices range between $10-$100 per bin. </h4> Because of the diverse
      nature of commercial wheelie bin cleaning please contact us for a free no
      obligation cleaning job quote to suit your individual requirements.
    </p>
  </div>
)
const ExtraMile = (
  <div>
    <h2>We go the extra mile</h2>
    <p>
      <h4>Quality service: </h4>
      Our experts are always looking to improve our service quality. Ask about
      the innovative deoderizing packages we provide to help improve your
      relationship with your rubbish bin.
      <br />
      <br />
      <h4> Cost effective: </h4> Our competitors are often over-priced. Call us
      to negotiate.
      <br />
      <br />
      <h4> Streamlined: </h4> Opt into our FREE inspection programme and never
      worry about your bins again. We do the hard work so you don't have to.
      Contact us to learn more.
    </p>
  </div>
)

const ChuteUnblocking = () => {
  const data = useStaticQuery(graphql`
    query {
      image1: file(relativePath: { eq: "bin-cleaning-1.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 624) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      image2: file(relativePath: { eq: "bin-cleaning-2.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 624) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      image3: file(relativePath: { eq: "bin-cleaning-3.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 624) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      image4: file(relativePath: { eq: "bin-cleaning-4.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 624) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  console.log(data)
  return (
    <Layout style={{ height: "80vh" }}>
      <SEO
        description="We will come to you and professionally clean your bin once-off or on a regular basis. From wheelie bins to industrial waste containers."
        lang="en"
        meta=""
        title="Bin Cleaning | ChuteOff | Brisbane, Gold Coast, Sunshine Coast "
      />
      <section id="top">
        <InfoWindow
          id="chute-cleaning"
          title={<>KEEP YOUR BINS SMELLING FRESH</>}
          subtitle="Once-off or regular bin cleaning in Brisbane, Gold Coast, and the Sunshine Coast!."
          panes={[InspectionReporting, RegularService, ExtraMile]}
          buttons={["CONTACT ON-CALL STAFF", "BOOK SERVICE", "CUSTOM JOB"]}
          button_subtitles={[
            "Sign on to regular cleans for a first-time discount.",
            "Not sure what you need? Talk to an expert",
            "Request a custom job tailored to your needs",
          ]}
          images={[
            data.image1.childImageSharp.fluid,
            data.image2.childImageSharp.fluid,
            data.image3.childImageSharp.fluid,
          ]}
          more={more}
          expansion_title={[
            "What does the bin cleaning service include?",
            "What will I receive as a regular client?",
            "Does ChuteOff offer me any extras?",
          ]}
        />
      </section>

      <section className="container">
        <div className="row pd-30">
          <div className="col-lg-6">
            <h2>A Fresh Bin Is Important</h2>
            <p>
              A dirty bin is an unhealthy bin, and if it smells, it needs
              professional attention.
            </p>
            <p>A fresh bin is important because it: </p>
            <ul
              class="list-unstyled"
              style={{
                listStyleType: "disc",
                paddingLeft: "2em",
                paddingBottom: "1.8em",
              }}
            >
              <li>Have a clean, fresh-smelling wheelie bin </li>
              <li>Removes pest, bug and rodent risks </li>
              <li>Keeps everyone happy </li>
              <li>Minimises long-term maintenance costs </li>
              <li>Removes grease build-up and potential fire hazards </li>
              <li>Reduces chances of black mold </li>
              <li>
                Reduce the risk of salmonella, listeria and other bacteria
              </li>
              <li>Reduce the risk of cross-contamination </li>
            </ul>
          </div>
          <div className="col-lg-6">
            <img
              fluid={data.image4.childImageSharp.fluid}
              alt=""
              style={{ width: "100%" }}
            />
          </div>
        </div>
      </section>
      <Process />
    </Layout>
  )
}

export default ChuteUnblocking
